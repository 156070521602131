@import "~@/styles/util/_index.scss";

.clebex-item-dl {
  padding: 4px;
  .inner-text {
    margin-left: 10px;
    font-size: 15px;
    span {
      color: $dusty-gray;
      .highlight {
        color: $bright-gray;
      }
    }
  }
}
